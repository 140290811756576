import axios from 'axios'
import _ from 'lodash'
import globalConfig from './config.json'

const baseUrl = globalConfig.apiEndpoint;
const apiCall = (method, requestUrl, data) => {
    const config = {
        url: requestUrl,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        },
    }

    if (method.toLowerCase() !== "get") {
        config.data = JSON.stringify(data);
    }

    return axios(config)
        .then(response => response.data);
}

export default {
    Link: {
        GetLinks(param) {
            try {
                let tpl = _.template("<%=baseUrl%>/Link/GetLinks?Name=<%=name%>&Type=<%=type%>&PageSize=<%=pageSize%>&CurrentPage=<%=currentPage%>&LastRecordId=<%=lastRecordId%>");
                let requestUrl = tpl({
                    baseUrl: baseUrl,
                    name: param.Name,
                    type: param.Type,
                    pageSize: param.PageSize,
                    currentPage: param.CurrentPage,
                    lastRecordId: param.LastRecordId
                });

                return apiCall("Get", requestUrl);
            } catch (error) {
                throw error;
            }
        },
        GetById(linkId) {
            try {
                let tpl = _.template("<%=baseUrl%>/Link/GetById?DocId=<%=linkId%>");
                let requestUrl = tpl({
                    baseUrl: baseUrl,
                    linkId: linkId,
                });

                return apiCall("Get", requestUrl);
            } catch (error) {
                throw error;
            }
        },
        Save(param) {
            try {
                let tpl = _.template("<%=baseUrl%>/Link/Save");
                let requestUrl = tpl({
                    baseUrl: baseUrl,
                });

                return apiCall("Post", requestUrl, param);
            } catch (error) {
                throw error;
            }
        },
        Delete(linkId) {
            try {
                let tpl = _.template("<%=baseUrl%>/Link/Delete");
                let requestUrl = tpl({
                    baseUrl: baseUrl,
                });

                return apiCall("Post", requestUrl, { DocId: linkId });
            } catch (error) {
                throw error;
            }
        }
    }
}
