import { useState, useEffect, useRef } from 'react';
import Api from '../../api'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import LinkForm from './LinkForm'
import { Avatar, Backdrop, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Snackbar, TextField, Tooltip } from '@material-ui/core';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
class GetLinkParam {
    constructor(type) {
        this.Type = type;
        this.Name = "";
        this.PageSize = 10;
        this.CurrentPage = 1;
        this.LastRecordId = "";
        this.PageCount = 0;
        this.UpdateContent = true;
    }
}

class CacheModel {
    constructor(currentPage, lastRecordId, name, data) {
        this.CurrentPage = currentPage;
        this.LastRecordId = lastRecordId;
        this.Name = name;
        this.Data = data;
    }
}

let timeout = 0;
let cache = [];

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 9999999,
        color: '#fff',
    },
}));

export default function LinkList({ type }) {
    const classes = useStyles();

    const [pagingParam, setPagingParam] = useState(new GetLinkParam(type));
    const [data, setData] = useState([]);
    const [viewList, setViewList] = useState(true);
    const [currentPath, setCurrentPath] = useState(window.location.href);
    const [editLinkId, setEditLinkId] = useState("");
    const [deleteData, setDeleteData] = useState({
        showConfigmDialog: false,
        linkId: ""
    });
    const [showBackdrop, setShowBackdrop] = useState(false)
    const [alert, setAlert] = useState({
        message: '',
        open: false,
        type: 'success'
    })

    const nameRef = useRef("");

    useEffect(() => {
        GetLinkList();
    }, [pagingParam]);

    useEffect(() => {
        cache = [];
        setPagingParam(new GetLinkParam(type));
    }, [type]);

    if (currentPath != window.location.href) {
        setViewList(true);
        setCurrentPath(window.location.href)
        return;
    }

    const deleteLink = (linkId) => {
        setShowBackdrop(true);
        Api.Link.Delete(linkId).then(function (response) {
            if (response.is_success) {

                setAlert({
                    message: response.msg,
                    open: true,
                    type: 'success'
                })


                setTimeout(() => {
                    cache = [];
                    setPagingParam(new GetLinkParam(type))
                }, 2000);
            } else {
                setAlert({
                    message: response.exception,
                    open: true,
                    type: 'error'
                })
            }
            setShowBackdrop(false);
        }).catch((response) => {
            setAlert({
                message: response.exception,
                open: true,
                type: 'error'
            })
            setShowBackdrop(false);
        });
    };

    const GetLinkList = async (tmpPaging) => {
        if (!pagingParam.UpdateContent && !tmpPaging) {
            return;
        }

        const name = tmpPaging ? tmpPaging.Name : pagingParam.Name;

        const linkCache = _.find(cache, (cacheItem) => {
            return cacheItem.CurrentPage === pagingParam.CurrentPage && cacheItem.Name === name;
        });

        if (linkCache) {
            setData(linkCache.Data);

            const lastRecordId = linkCache.Data.length > 0 ? linkCache.Data[linkCache.Data.length - 1].DocId : "";
            if (linkCache.Data.length > 0 && lastRecordId.length > 0 && lastRecordId !== pagingParam.LastRecordId) {
                setPagingParam((prev) => ({
                    ...prev,
                    LastRecordId: lastRecordId,
                    UpdateContent: false
                }))
            }
            return;
        }

        const param = {
            Name: tmpPaging ? tmpPaging.Name : pagingParam.Name,
            PageSize: tmpPaging ? tmpPaging.PageSize : pagingParam.PageSize,
            CurrentPage: tmpPaging ? tmpPaging.CurrentPage : pagingParam.CurrentPage,
            LastRecordId: tmpPaging ? tmpPaging.LastRecordId : pagingParam.LastRecordId,
            Type: pagingParam.Type
        }

        setShowBackdrop(true);

        Api.Link.GetLinks(param).then(function (response) {
            if (response.is_success) {
                setData(response.data);

                cache = [...cache, new CacheModel(
                    tmpPaging ? tmpPaging.CurrentPage : pagingParam.CurrentPage,
                    tmpPaging ? tmpPaging.LastRecordId : pagingParam.LastRecordId,
                    tmpPaging ? tmpPaging.Name : pagingParam.Name,
                    response.data
                )]

                if (pagingParam.PageCount === 0 && response.data.length < pagingParam.PageSize) {
                    setPagingParam((prev) => ({
                        ...prev,
                        PageCount: prev.CurrentPage,
                        UpdateContent: false
                    }))
                }

                if (response.data.length > 0) {
                    setPagingParam((prev) => ({
                        ...prev,
                        LastRecordId: response.data[response.data.length - 1].DocId,
                        UpdateContent: false
                    }))
                }
            } else {
                setAlert({
                    message: response.exception,
                    open: true,
                    type: 'error'
                })
            }
            setShowBackdrop(false);
        }).catch((response) => {
            setAlert({
                message: response.message,
                open: true,
                type: 'error'
            })
            setShowBackdrop(false);
        });
    }

    const columns = [
        { field: 'Name', title: 'Name', searchable: false },
        {
            field: 'ProfileImage', title: 'ProfileImage', searchable: false, render: rowData => (
                <>
                    <Avatar variant="square" className={classes.square} src={
                        rowData.ProfileImage.length === 0 ? "" : rowData.ProfileImage[0].url
                    }>
                    </Avatar>
                </>
            )
        },
        { field: 'Address', title: 'Address', searchable: false },
        { field: 'Phone', title: 'Phone', searchable: false },
        { field: 'Email', title: 'Email', searchable: false },
        { field: 'Website', title: 'Website', searchable: false },
        { field: 'Intro', title: 'Intro', searchable: false },
        { field: 'IntroVideo', title: 'IntroVideo', searchable: false },
        { field: 'Niches', title: 'Niches', searchable: false },
        { field: 'ProfilePages', title: 'ProfilePages', searchable: false },
        {
            field: 'DocId',
            title: '',
            searchable: false,
            render: rowData => (
                <>
                    <Tooltip title="Edit">
                        <Button color="primary" onClick={() => {
                            setEditLinkId(rowData.DocId);
                            setViewList(false);
                        }}>
                            <EditIcon></EditIcon>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button color="secondary" onClick={() => setDeleteData({
                            showConfigmDialog: true,
                            linkId: rowData.DocId
                        })}>
                            <DeleteIcon></DeleteIcon>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Preview">
                        <Button color="primary" onClick={() => {
                            const newWindow = window.open(rowData.ShortLink, '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        }}>
                            <VisibilityIcon></VisibilityIcon>
                        </Button>
                    </Tooltip>
                </>
            )

        },
    ]

    if (viewList) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid container style={{ marginBottom: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                label="Search"
                                onChange={(event) => {
                                    nameRef.current = event.target.value;

                                    setPagingParam((prev) => ({
                                        ...prev,
                                        Name: event.target.value,
                                        LastRecordId: "",
                                        UpdateContent: false
                                    }))

                                    if (timeout) {
                                        clearTimeout(timeout);
                                    }

                                    timeout = setTimeout(() => {
                                        const tmpPaging = new GetLinkParam();
                                        tmpPaging.Name = nameRef.current;
                                        GetLinkList(tmpPaging);
                                    }, 500);
                                }}
                                value={pagingParam.Name}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => { setViewList(false); setEditLinkId("") }}
                                variant="contained"
                                color="primary"
                                style={{ float: "right" }}
                            >Create New</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        data={data}
                        columns={columns}
                        pageSize={pagingParam.PageSize}
                        options={{
                            search: false,
                            toolbar: false,
                            paging: false,
                            sorting: false
                        }}
                    >
                    </MaterialTable>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            style={{ float: "right", marginTop: "15px" }}
                        >
                            <Button
                                disabled={pagingParam.CurrentPage == 1}
                                onClick={() => {
                                    if (pagingParam.CurrentPage === 1) {
                                        return;
                                    }
                                    setPagingParam((prev) => ({
                                        ...prev,
                                        CurrentPage: prev.CurrentPage - 1,
                                        UpdateContent: true
                                    }));
                                }}
                            >Back</Button>
                            <Button>
                                {pagingParam.CurrentPage}
                            </Button>
                            <Button
                                disabled={pagingParam.CurrentPage == pagingParam.PageCount}
                                onClick={() => {
                                    setPagingParam((prev) => ({
                                        ...prev,
                                        CurrentPage: prev.CurrentPage + 1,
                                        UpdateContent: true
                                    }));
                                }}
                            >Next</Button>
                        </ButtonGroup>
                    </div>
                </Grid>
                <Dialog
                    open={deleteData.showConfigmDialog}
                    onClose={() => setDeleteData((prev) => ({
                        ...prev,
                        showConfigmDialog: false
                    }))}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you sure want to delete?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteData((prev) => ({
                            ...prev,
                            showConfigmDialog: false
                        }))} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            setDeleteData((prev) => ({
                                ...prev,
                                showConfigmDialog: false
                            }));
                            deleteLink(deleteData.linkId);
                        }} color="primary">
                            Delete anyway
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop className={classes.backdrop} open={showBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={alert.open}
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={(e) => setAlert(prev => ({
                        ...prev,
                        open: false
                    }))}>
                    <Alert severity={alert.type} variant="filled">
                        {alert.message}
                    </Alert>
                </Snackbar>
            </Grid>
        )
    } else {
        return (
            <LinkForm
                type={type}
                onBackList={() => {
                    setViewList(true);
                    cache = [];
                    setPagingParam(new GetLinkParam(type))
                }}
                linkId={editLinkId}
            >
            </LinkForm>
        )
    }
}