import { makeStyles } from '@material-ui/core/styles'

import { useEffect, useState } from 'react'
import React from 'react'

const useStyles = makeStyles(() => ({
    previewImage: {
        width: "250px",
    }
}));

const fileUpload = React.createRef();

const onImageClick = (e) => {
    fileUpload.current.click();
}

const defaultImage = "/images/default-placeholder.png";

export default function LinkForm({
    ImageUrl,
    onChange = () => { }
}) {
    const classes = useStyles();
    const [imageData, setImageData] = useState();

    useEffect(() => {
        if (ImageUrl && ImageUrl.length > 0) {
            setImageData(ImageUrl);
        } else {
            setImageData(defaultImage);
        }
    }, [ImageUrl])

    const onFileChanged = (event) => {
        let input = event.currentTarget;
        if (input.files && input.files[0]) {
            const file = input.files[0];

            if (!file.type.startsWith('image/')) { return }

            const img = document.createElement("img");
            // img.classList.add("img-preview");
            img.file = file;

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (function (img) {
                return function (e) {
                    let img = new Image();
                    img.src = e.target.result;

                    let elem = document.createElement("canvas");

                    img.onload = function () {
                        // Update dimensions of the canvas with the dimensions of the image
                        let MAX_WIDTH = 400;
                        let MAX_HEIGHT = 400;
                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        elem.width = width;
                        elem.height = height;

                        // Draw the image
                        const ctx = elem.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);

                        // const data = ctx.canvas.toDataURL(img, mime, quality);
                        ctx.canvas.toBlob((blob) => {
                            var reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = function () {
                                var base64data = reader.result;
                                setImageData(base64data)
                                onChange(base64data);
                            }
                        }, 'image/png', 0.85);

                    };
                };

            })(img);
        }
    }

    return (
        <div>
            <img
                src={imageData}
                className={classes.previewImage}
                onClick={(e) => onImageClick(e)}
            >
            </img>
            <input
                type="file"
                style={{ "display": "none" }}
                ref={fileUpload}
                onChange={(e) => onFileChanged(e)}
            >
            </input>
        </div >
    );
};