import { makeStyles } from "@material-ui/core/styles";

import { useEffect, useState } from "react";
import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    pages: {
        paddingLeft: "30px",
    },
    pageItem: {
        marginBottom: "10px"
    },
}));

export default function ProfilePages({
    Pages,
    onChange = () => { }
}) {
    const classes = useStyles();
    const [pageData, setPageData] = useState(Pages);

    useEffect(() => {
        setPageData(Pages);
    }, [Pages]);

    return (
        <div className={classes.pages}>
            {
                pageData && pageData.map((pageUrl, index) => {
                    return (
                        <Grid item xs={12} key={index} className={classes.pageItem}>
                            <TextField
                                id={"page-item-" + index}
                                label="Page"
                                fullWidth
                                value={pageUrl}
                                onChange={(e) => {
                                    let newPages = [...pageData];
                                    let index = parseInt(e.currentTarget.attributes["data-index"].value);
                                    newPages[index] = e.currentTarget.value;
                                    setPageData(newPages);
                                    onChange(newPages);
                                }}
                                inputProps={{ "data-index": index }}
                            />
                        </Grid>
                    )
                })
            }
            <Button
                variant="contained"
                onClick={() => setPageData(prev => [...prev, ""])}>
                Add Page
            </Button>
        </div>
    );
}
