import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import LeftNav from '../Layout/LeftNav'
import LinkList from '../Link/LinkList'

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        marginTop: "60px"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Router>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx(classes.drawerPaper),
                        }}
                    >
                        <LeftNav></LeftNav>
                    </Drawer>
                    <main className={classes.content}>
                        <Container maxWidth="lg" className={classes.container}>
                            <Switch>
                                <Route path="/company">
                                    <LinkList type="Company"></LinkList>
                                </Route>
                                <Route path="/person">
                                    <LinkList type="Person"></LinkList>
                                </Route>
                                <Route path="/job">
                                    job
                            </Route>
                            </Switch>
                        </Container>
                    </main>
                </Router>
            </div>
        </>
    );
}