import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import List from '@material-ui/core/List';


const useStyles = makeStyles((theme) => ({
    toolBar: {
        display: "flex",
        "justify-content": "flex-end"
    },
    menuItem: {
        display: "block",
        padding: "10px",
        "text-decoration": "none",
    },
    navMenu: {
        display: "flex",
        "margin-right": "auto"
    },
    navMenuRight: {
        display: "flex",
        "margin-left": "auto"
    },
}));

export default function LeftNav() {
    const classes = useStyles();

    return (
        <List>
            <ListItem button>
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <Link className={classes.menuItem} to="/company">Company</Link>
            </ListItem>
            <ListItem button>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <Link className={classes.menuItem} to="/person">Person</Link>
            </ListItem>
            {/* <ListItem button>
                <ListItemIcon>
                    <WorkIcon />
                </ListItemIcon>
                <Link className={classes.menuItem} to="/job">Job</Link>
            </ListItem> */}
        </List>
    );
}