import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, Grid, InputLabel, Snackbar, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

import { useEffect, useState } from 'react'
import ImageUpload from '../Form/ImageUpload'
import { Editor } from '@tinymce/tinymce-react'
import Config from '../../config.json'
import { Alert, Autocomplete } from '@material-ui/lab';
import ProfilePages from '../Form/ProfilePages';
import Api from '../../api';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    textItem: {
        width: "100%"
    },
    saveButton: {
        float: "right"
    },
    introEditorError: {
        border: "solid red"
    },
    introLabelError: {
        color: "red"
    },
    helperText: {
        fontSize: "0.75rem",
        marginTop: "3px"
    },
    errorHelperText: {
        color: "red",
        display: "block !important",
    },
    backdrop: {
        zIndex: 9999999,
        color: '#fff',
    },
}));

export default function LinkForm({ type, onBackList = () => { }, linkId }) {
    const classes = useStyles();

    //init state
    const [values, setValues] = useState({
        Name: '',
        Address: '',
        Phone: '',
        Email: '',
        Website: '',
        Intro: '',
        IntroVideo: '',
        ProfileImage: '',
        ProfileImageBase64: '',
        Niches: '',
        ProfilePages: [],
        CurrentCompanyName: '',
        CurrentCompanyId: '',
        GpsLat: '',
        GpsLong: '',
        Position: '',
        Type: type
    })
    const [alert, setAlert] = useState({
        message: '',
        open: false,
        type: 'success'
    })
    const [validation, setValidation] = useState({
        NameError: false,
        IntroError: false
    })
    const [showBackdrop, setShowBackdrop] = useState(false)
    const [existedCompany, setExistedCompany] = useState(false)
    const [companies, setCompanies] = useState([])

    //init data
    useEffect(() => {
        if (linkId != "") {
            setShowBackdrop(true);
            Api.Link.GetById(linkId).then(function (response) {
                if (response.is_success) {
                    setValues((prev) => ({
                        ...response.data.Data,
                        DocId: linkId
                    }))
                } else {
                    setAlert({
                        message: response.exception,
                        open: true,
                        type: 'error'
                    })
                }

                setShowBackdrop(false);
            }).catch((response) => {
                setAlert({
                    message: response.exception,
                    open: true,
                    type: 'error'
                })

                setShowBackdrop(false);
            });

            var param = {
                Name: "",
                PageSize: 1000,
                CurrentPage: 10,
                Type: "Company"
            }

            Api.Link.GetLinks(param).then(function (response) {
                debugger;
                if (response.is_success) {
                    setCompanies(response.data)
                }
            }).catch((err) => {
            });
        }
    }, []);

    //save form
    const onSaveForm = (values) => {
        if (values.Name.length === 0) {
            setValidation((prev) => ({
                ...prev,
                NameError: true
            }))
        }

        if (values.Name.length === 0) {
            setValidation((prev) => ({
                ...prev,
                IntroError: true
            }))
        }

        if (validation.NameError || validation.IntroError) {
            return;
        }

        var param = { ...values };
        if (existedCompany) {
            const selectedCompany = _.find(companies, (company) =>
                company.DocId === param.CurrentCompanyId
            );

            if (selectedCompany) {
                param.CurrentCompanyName = selectedCompany.Name;
            }
        } else {
            param.CurrentCompanyId = '';
        }

        setShowBackdrop(true);

        Api.Link.Save(param)
            .then(function (response) {
                if (response.is_success) {
                    setAlert({
                        message: response.msg,
                        open: true,
                        type: 'success'
                    })

                    setTimeout(() => {
                        onBackList();
                    }, 2000);
                } else {
                    setAlert({
                        message: response.exception,
                        open: true,
                        type: 'error'
                    })
                }
                setShowBackdrop(false);
            }).catch((response) => {
                setAlert({
                    message: response.exception,
                    open: true,
                    type: 'error'
                })

                setShowBackdrop(false);
            });;
        ;
    };

    return (
        <form>
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={alert.open}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={(e) => setAlert(prev => ({
                    ...prev,
                    open: false
                }))}>
                <Alert severity={alert.type} variant="filled">
                    {alert.message}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => onBackList()}>
                        Back To List
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <Button
                        className={classes.saveButton}
                        variant="contained"
                        color="primary"
                        onClick={() => onSaveForm(values)}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        id="link-name"
                        label="Name (*)"
                        fullWidth
                        value={values.Name}
                        helperText={validation.NameError ? "Name is required" : ""}
                        onChange={(e) => {
                            setValues(prev => ({
                                ...prev,
                                Name: e.target.value
                            }));
                            setValidation(prev => ({
                                ...prev,
                                NameError: e.target.value.length === 0
                            }))
                        }}
                        error={validation.NameError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="link-address"
                        label="Address"
                        fullWidth
                        value={values.Address}
                        onChange={(e) => setValues(prev => ({
                            ...prev,
                            Address: e.target.value
                        }))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="link-phone"
                        label="Phone"
                        fullWidth
                        value={values.Phone}
                        onChange={(e) => setValues(prev => ({
                            ...prev,
                            Phone: e.target.value
                        }))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="link-email"
                        label="Email"
                        fullWidth
                        value={values.Email}
                        onChange={(e) => setValues(prev => ({
                            ...prev,
                            Email: e.target.value
                        }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="link-website"
                        label="Website"
                        fullWidth
                        value={values.Website}
                        onChange={(e) => setValues(prev => ({
                            ...prev,
                            Website: e.target.value
                        }))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="link-intro-video"
                        label="Intro Video"
                        fullWidth
                        helperText="Intro video is youtube video's id"
                        value={values.IntroVideo}
                        onChange={(e) => setValues(prev => ({
                            ...prev,
                            IntroVideo: e.target.value
                        }))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        id="link-niches"
                        fullWidth
                        value={values.Niches}
                        options={Config.niches}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Niches" />}
                        onChange={(e, value) => setValues(prev => ({
                            ...prev,
                            Niches: value
                        }))}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="link-lat"
                        label="Gps Latitude"
                        fullWidth
                        value={values.GpsLat}
                        onChange={(e) => setValues(prev => ({
                            ...prev,
                            GpsLat: e.target.value
                        }))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="link-lng"
                        label="Gps Longitude"
                        fullWidth
                        value={values.GpsLong}
                        onChange={(e) => setValues(prev => ({
                            ...prev,
                            GpsLong: e.target.value
                        }))}
                    />
                </Grid>
                {type == "Person" &&
                    (
                        <>
                            {existedCompany &&
                                (
                                    <Grid item xs={10}>
                                        <Autocomplete
                                            id="link-company-id"
                                            fullWidth
                                            options={companies}
                                            getOptionLabel={(option) => option.Name}
                                            renderInput={(params) => <TextField {...params} label="Current Company" />}
                                            onChange={(e, value) => setValues(prev => ({
                                                ...prev,
                                                CurrentCompanyId: value.DocId
                                            }))}
                                        />
                                    </Grid>
                                )
                            }
                            {!existedCompany &&
                                (
                                    <Grid item xs={10}>
                                        <TextField
                                            id="link-company-name"
                                            label="Current Company"
                                            fullWidth
                                            value={values.CurrentCompanyName}
                                            onChange={(e) => setValues(prev => ({
                                                ...prev,
                                                CurrentCompanyName: e.target.value
                                            }))}
                                        />
                                    </Grid>
                                )
                            }
                            <Grid item xs={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={existedCompany}
                                            onChange={(e) => setExistedCompany(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Company existed"
                                />

                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="link-position"
                                    label="Position"
                                    fullWidth
                                    value={values.Position}
                                    onChange={(e) => setValues(prev => ({
                                        ...prev,
                                        Position: e.target.value
                                    }))}
                                />
                            </Grid>
                        </>
                    )
                }

                <Grid item xs={12}>
                    <InputLabel style={{ marginBottom: "15px" }}>
                        Profile Image
                    </InputLabel>
                    <ImageUpload
                        onChange={(base64Image) => setValues(prev => ({
                            ...prev,
                            ProfileImageBase64: base64Image
                        }))}
                        ImageUrl={
                            values.ProfileImageBase64 && values.ProfileImageBase64.length > 0 ?
                                values.ProfileImageBase64 :
                                values.ProfileImage && values.ProfileImage.length > 0 ? values.ProfileImage[0].url : ""}
                    />
                    <div className={classes.helperText}>
                        Click to image to upload
                    </div>
                </Grid>
                <Grid item xs={12} >
                    <InputLabel style={{ marginBottom: "15px" }} className={validation.IntroError ? classes.introLabelError : ""}>
                        Intro (*)
                    </InputLabel>
                    <div className={validation.IntroError ? classes.introEditorError : ""}>
                        <Editor
                            key="w8y95e7gbqntwr21xzaxj2fxbq8k1l58ykkwvq6bzei99m8g"
                            initialValue={values.Intro}
                            value={values.Intro}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={(content, editor) => {
                                setValues(prev => ({
                                    ...prev,
                                    Intro: content
                                }))
                                setValidation(prev => ({
                                    ...prev,
                                    IntroError: content.length === 0
                                }))
                            }}
                        />
                    </div>
                    <div style={{ display: "none" }} className={validation.IntroError ? classes.helperText + " " + classes.errorHelperText : classes.helperText}>
                        Intro is required
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel style={{ marginBottom: "15px" }}>
                        Profile Pages
                    </InputLabel>
                    <ProfilePages
                        Pages={values.ProfilePages}
                        onChange={(pages) => setValues(prev => ({
                            ...prev,
                            ProfilePages: _.filter(pages, (page) => page.trim().length > 0)
                        }))}
                    />
                </Grid>
            </Grid>
        </form>
    );
};