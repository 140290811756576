import Layout from './components/Layout/Layout';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const THEME = createMuiTheme({
  typography: {
    "fontFamily": "'Google Sans', 'Segoe UI', 'Arial', 'sans-serif'",
  }
});

function App() {
  return (
    <MuiThemeProvider theme={THEME}>
      <Layout></Layout>
    </MuiThemeProvider>
  );
}

export default App;
